import { MdHome, MdLock, MdLockOutline } from 'react-icons/md';

// Admin Imports
import Dashboard from 'views/admin/main/buyer/sellerOffer';

// Auth Imports
import LockCentered from 'views/auth/lock/LockCenter';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCenter';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import SignUpSeller from 'views/auth/signUp/SignUpSeller';
import ProfileSettings from 'views/admin/main/profile/settings';
import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';
import StripeBilling from 'views/admin/main/account/stripe';
import MyOffer from 'views/admin/main/buyer/myOffer';

const routesBuyer = [
  {
    name: 'Offres vendeurs',
    layout: '/admin',
    path: '/dashboard',
    icon: <MdHome className="text-inherit h-6 w-6" />,
    component: <Dashboard />,
    menu: true,
  },
  {
    name: 'Mes demandes',
    layout: '/admin',
    path: '/my-request',
    icon: <MdHome className="text-inherit h-6 w-6" />,
    component: <MyOffer />,
    menu: true,
  },
  {
    name: 'Profile Settings',
    layout: '/admin',
    path: '/settings',
    exact: false,
    icon: <MdHome className="text-inherit h-6 w-6" />,
    component: <ProfileSettings />,
    menu: false,
  },
  {
    name: 'Leads',
    layout: '/admin',
    path: '/leads',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    availble: false,
    menu: true,
  },
  {
    name: 'Reporting',
    layout: '/admin',
    path: '/reporting',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    availble: false,
    menu: true,
  },
  {
    name: 'Paiement',
    layout: '/admin',
    path: '/paiement',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    availble: false,
    menu: true,
  },
  {
    name: 'Attente de validation',
    layout: '/admin',
    path: '/seller/integrate',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceReferrals />,
  },
  {
    name: 'Créer une offre',
    layout: '/admin',
    path: '/new-offer',
    exact: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceNewProduct />,
  },
  {
    name: 'Abonnements',
    layout: '/admin',
    path: '/subscribe',
    exact: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <StripeBilling />,
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    menu: false,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up',
            component: <SignUpDefault />,
          },
        ],
      },
      {
        name: 'Sign Up',
        path: '/seller/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/seller/sign-up',
            component: <SignUpSeller />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
    ],
  },
];
export default routesBuyer;
