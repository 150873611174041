import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CONFIG } from 'variables/config';

const Information = () => {
  const [user, setUser] = useState(null);
  const [defaultUser, setDefaultUser] = useState(null);
  const nav = useNavigate();

  const initUser = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        const user = res.data;
        setUser(user);
        setDefaultUser(user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value });
  };

  const updateProfil = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      email: user.email,
      name: user.name,
      surname: user.surname,
      website: user.website,
      redirectionLink: user.redirectionLink,
    };
    axios
      .put(`${CONFIG.apiUrl}/user`, data, config)
      .then(res => {
        if (defaultUser.website !== user.website)
          nav('/admin/seller/integrate');
        console.log(res);
        toast.success('Votre profil a été mis à jour');
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    initUser();
  }, []);

  return (
    <Card extra={'w-full mt-3 px-6 py-6 border-2'}>
      <ToastContainer />
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Mon compte {user?.type === 'seller' ? 'vendeur' : 'acheteur'}
        </h4>
      </div>
      {/* inputs */}
      <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">
        <InputField
          extra="mb-3"
          label="Adresse mail"
          placeholder={user?.email}
          id="email"
          type="text"
          onChange={handleChange}
        />
        <InputField
          extra="mb-3"
          label="Prénom"
          placeholder={user?.name}
          id="name"
          type="text"
          onChange={handleChange}
        />
        <InputField
          extra="mb-3"
          label="Nom"
          placeholder={user?.surname}
          id="surname"
          type="text"
          onChange={handleChange}
        />
        {user?.type === 'seller' && (
          <>
            <InputField
              extra="mb-3"
              label="URL de ma landing page"
              placeholder={user?.website || 'https://leadrs.co'}
              id="website"
              type="text"
              onChange={handleChange}
            />
            <p className="px-1 col-span-2 w-full text-sm text-red-400">
              Un changement de landing page entraine une
              nouvelle intégration.
            </p>
            <InputField
              extra="mb-3"
              label="URL de confirmation"
              placeholder={user?.redirectionLink || 'https://leadrs.co'}
              id="redirectionLink"
              type="text"
              onChange={handleChange}
            />
          </>
        )}
      </div>
      <div className="mt-1 flex w-full justify-end">
        <button
          className="linear mt-2 rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => updateProfil()}
        >
          Sauvegarder
        </button>
      </div>
    </Card>
  );
};

export default Information;
