import Earn from "./components/Earn";
import Invite from "./components/Invite";
// Assets
const Referrals = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-12 gap-5 rounded-[20px]">
      <div className="col-span-12 w-full 3xl:col-span-8">
        <Earn />
      </div>
      <div className="col-span-12 w-full 3xl:col-span-4">
        <Invite />
      </div>
    </div>
  );
};

export default Referrals;
