//import { useNavigate } from 'react-router-dom';
import Information from './components/Information';
import Password from './components/Password';
import Card from 'components/card';
import { useState } from 'react';
import GoBack from 'components/actions/GoBack';

const ProfileSetting = () => {
  const [wantToDelete, setWantToDelete] = useState(false);
  return (
    <>
      <div className="flex">
        <GoBack />
      </div>
      <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 lg:grid-cols-2">
        <div className="rounded-[20px]">
          <div className="mt-3">
            <Information />
          </div>
        </div>
        <div>
          <Card extra="mt-3 !flex-row py-6 px-[30px] justify-between items-center w-full border-2">
            <div>
              <h3 className="mb-px text-lg font-bold text-navy-700 dark:text-white">
                Tester mon intégration
              </h3>
              <p className="mt-4 text-base text-gray-600">
                Vous pouvez à tout moment tester la connexion entre votre
                landing page et leadrs.co.
              </p>
              <p className="mt-4 text-base text-gray-600">
                {' '}
                Pour se faire indiquer la valeur <b>'testing@leadrs.co'</b> dans
                n'importe lequel des champs de votre formulaire.
              </p>
              <p className="mt-4 text-base text-gray-600">
                Si vous êtes correctement intégré vous recevrez un mail de confirmation, sinon modifiez l'url de votre landing page et suivez le guide pour rétablir la connexion.
              </p>
            </div>
          </Card>
        </div>
        <div className="">
          <div>
            <Password />
          </div>
        </div>
        <div>
          <Card extra="mt-3 !flex-row py-6 px-[30px] justify-between items-center w-full border-2">
            <div>
              <h3 className="mb-px text-lg font-bold text-navy-700 dark:text-white">
                Supprimer ce compte
              </h3>
              <p className="text-base text-gray-600">
                Attention cette action est irréversible
              </p>
            </div>
            {wantToDelete && (
              <div className="flex flex-col items-center justify-center">
                <p className="text-xs text-red-500">
                  Vous pouvez faire supprimer votre compte en envoyant un mail à
                  l'adresse suivante: charlotte@leadrs.co
                </p>
              </div>
            )}
            <button
              className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-4 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
              onClick={() => setWantToDelete(!wantToDelete)}
            >
              Supprimer
            </button>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
