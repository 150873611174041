import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';

import SolarImg from 'assets/offers/solar.png';
import HeatImg from 'assets/offers/heat.jpeg';
import InvestImg from 'assets/offers/invest.jpg';
import formationImg from 'assets/offers/formation.jpg';
import AssuranceImg from 'assets/offers/assurance.jpg';

import Card from 'components/card';
import OfferCard from './components/OfferCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ModalBuyer from './components/Modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { CONFIG } from 'variables/config';

const getImage  = (industry: string, subIndustry: string) => {
  if (industry === 'Formation') return formationImg;
  if (industry === 'Assurance') return AssuranceImg;
  if (industry === 'Investissement') return InvestImg;
  if (subIndustry === 'Panneaux solaire') return SolarImg;
  if (subIndustry === 'Pompe à chaleur') return HeatImg;
}

const Dashboard = () => {
  const [offer, setOffer] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initOffer = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${CONFIG.apiUrl}/seller-offer/marketplace`, config)
      .then(res => {
        const offers = res.data;
        //offers[0].status = "prepare";
        setOffer(offers);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const initUser = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        const user = res.data;
        //user.role = "paying-user";
        setUser(user);
		if (user && user.type !== 'buyer') {
			// if (user.isAlreadyConnected)
			// 	window.location.href = '/admin/dashboard';
			// else
			// 	window.location.href = '/admin/seller/integrate';
		}
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const openPortal = () => {
    window.location.href = `https://billing.stripe.com/p/login/00g4iQ4aC0R76pW4gg?prefilled_email=${user.email}`;
    return <p>Redirection en cours...</p>;
  };

  useEffect(() => {
    initOffer();
    initUser();
  }, []);

  return (
    <div className="flex h-full w-full flex-col rounded-[20px] xl:flex-row ">
      <ModalBuyer
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        selectedOffer={selectedOffer}
        user={user}
      />
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          {/* Your Transfers & tables */}
          <div className="grid w-full grid-cols-12 gap-2">
            <div className="col-span-12">
              <div className={'h-full w-full px-1'}>
                <div className="grid grid-cols-12">
                  <div className="col-span-12 flex justify-between">
                    <p className="mt-1 text-lg font-bold text-navy-700 dark:text-white lg:text-xl">
                      Nos offres
                    </p>
                    <div className="">
                      {user && user.role !== 'paying-user' ? (
                        <button
                          className="linear flex items-center justify-center rounded-xl bg-brand-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                          onClick={() =>
                            (window.location.href = '/admin/subscribe')
                          }
                        >
                          S'abonner
                        </button>
                      ) : (
                        <button
                          className="linear mt-4 flex items-center justify-center rounded-xl bg-leadrs px-5 py-2 text-xs font-medium text-white transition duration-200 hover:bg-leadrsLight"
                          onClick={() => openPortal()}
                        >
                          Gérer mon abonnement
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
                  {offer.map(item => (
                    <OfferCard
                      bidders={[avatar1, avatar2, avatar3]}
                      industry={item.industry}
                      subIndustry={item.subIndustry}
                      targetPrice={item.targetPrice}
                      weeklyQuantity={Number(item.weeklyQuantity)}
                      leadsGenerated={Number(item.leadsGenerated)}
                      otherIndustry={item.otherIndustry}
                      buyerPrice={item.buyerPrice}
                      user={user}
                      status={
                        user && user.role === 'paying-user'
                          ? item.status
                          : 'not-paid'
                      }
                      image={getImage(item.industry, item.subIndustry)}
                      onClick={() => {
                        setSelectedOffer(item);
                        onOpen();
                      }}
                    />
                  ))}
                  {user && user.role && user.role !== 'user' && (
                    <Card
                      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 border-leadrs border-dashed shadow-xl cursor-pointer`}
                    >
                      <div className="mx-auto my-auto items-center text-center">
                        <h1 className="text-2xl font-bold text-leadrs">
                          Besoin de<br></br>nouveau leads ?
                        </h1>
                        <button
                          className="mx-auto mt-4 flex rounded-2xl bg-leadrs px-5 py-3 text-xs font-bold text-white hover:bg-leadrsLight"
                          onClick={() => (window.location.href = 'new-offer')}
                        >
                          Passer commande
                        </button>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-span-12 sm:col-span-3">
              <OrderStatus offer={offer[0]} />
            </div> */}
            {/* <div className="col-span-6 3xl:col-span-2">
              <YourCard />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
