import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import axios from 'axios';
import Card from 'components/card';
import { useEffect, useState } from 'react';
import {
  MdDisplaySettings,
  MdDoneOutline,
  MdOutlinePayment,
  MdOutlineRocketLaunch,
} from 'react-icons/md';
import { CONFIG } from 'variables/config';

const ModalBuyer = (props: {
  isOpen: any;
  onOpen: any;
  onClose: any;
  selectedOffer: any;
  user: any;
}) => {
  const { isOpen, onClose, selectedOffer, user } = props;

  const [pageState, setPageState] = useState('steps');

  const InfoBox = (props: {
    icon?: any;
    title?: string;
    description?: string;
  }) => {
    const { icon, title, description } = props;
    return (
      <div className="w-full rounded-[20px] bg-lightPrimary p-6 dark:!bg-navy-700">
        <div className="grid grid-cols-12 gap-0">
          <div className="col-span-1 text-gray-900">{icon}</div>
          <h4 className="col-span-10 text-xl font-bold text-gray-900 dark:text-white">
            {title}
          </h4>
        </div>
        <p className="mt-[12px] pr-2 text-base text-gray-700 dark:text-white">
          {description}
        </p>
      </div>
    );
  };

  const deleteOffer = (id: string) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .delete(`${CONFIG.apiUrl}/offer/${id}`, config)
      .then((res: any) => {
        console.log(res);
        onClose();
      })
  };

  useEffect(() => {
    console.log(selectedOffer);
  }, [selectedOffer]);

  return (
    <>
      {selectedOffer && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
            <ModalBody>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[750px] flex flex-col !z-[1004] h-[700px] lg:[300px] xl:h-[400px] overflow-y-scroll">
                <button
                  className={`linear absolute right-[30px] w-6 rounded-full border-2 border-red-500 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10`}
                  onClick={onClose}
                >
                  X
                </button>
                <h1 className="mb-1 text-xl font-bold">
                  {selectedOffer.subIndustry}
                </h1>
                <h1 className="mb-2 text-lg">{selectedOffer.industry}</h1>
                <div className="h-full w-full flex-col items-center px-4 lg:px-3">
                  <div className="mt-2 flex w-full justify-between gap-0 overflow-hidden border-b border-gray-200 text-3xl sm:gap-0 md:gap-2 lg:gap-[50px] xl:gap-14 2xl:justify-center 2xl:gap-[70px]">
                    <div
                      className={
                        pageState === 'steps'
                          ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                          : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                      }
                      onClick={() => setPageState('steps')}
                    >
                      <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                        Étapes
                      </p>
                    </div>

                    <div
                      className={
                        pageState === 'informations'
                          ? ' flex items-center gap-2 border-b-[4px] border-[#0260CB] pb-3 hover:cursor-pointer dark:border-brand-400'
                          : 'flex items-center gap-2 pb-[20px] hover:cursor-pointer'
                      }
                      onClick={() => setPageState('informations')}
                    >
                      <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                        Informatons
                      </p>
                    </div>
                  </div>
                </div>
                {pageState === 'steps' && (
                  <>
                    {selectedOffer.status === 'waiting_subscription' && (
                      <div
                        className="hover:cursor-pointer"
                        onClick={() => {
                          window.location.href = '/admin/subscribe';
                        }}
                      >
                        <InfoBox
                          icon={<MdOutlinePayment size={30} />}
                          title={'Abonnement nécessaire'}
                          description={`Vous devez être abonné pour pouvoir publier cette offre.`}
                        />
                        <button
                          className="linear mx-auto mt-2 w-full rounded-xl border-2 border-leadrs px-5 py-2 text-sm font-medium text-leadrs transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5"
                          onClick={() => {
                            window.location.href = '/admin/subscribe';
                          }}
                        >
                          S'abonner
                        </button>
                      </div>
                    )}
                    {selectedOffer.status === 'on_market' &&
                      user &&
                      user.isValidated && (
                        <InfoBox
                          icon={<MdDisplaySettings size={30} />}
                          title={'Paramétrage de la campagne'}
                          description={`Votre campagne est en cours de paramétrage. Vous receverez un mail avec un lien de paiement une fois que tout sera prêt !`}
                        />
                      )}
                    {selectedOffer.status === 'on_market' &&
                      user &&
                      !user.isValidated && (
                        <InfoBox
                          icon={<MdDisplaySettings size={30} />}
                          title={'En attente de validation'}
                          description={`Votre compte doit être validé par un administrateur, votre offre sera publiée une fois que ce sera fait.`}
                        />
                      )}
                    {selectedOffer.status === 'waiting_payment' && (
                      <InfoBox
                        icon={<MdOutlinePayment size={30} />}
                        title={'En attente de paiement'}
                        description={`Vous avez commandé ${
                          selectedOffer.weeklyQuantity
                        } leads à ${selectedOffer.buyerPrice.toFixed(
                          2
                        )}€ l'unité. Votre campagne commence une fois la commande payé. Votre argent reste sous séquestre jusqu'à la livraison complète.`}
                      />
                    )}
                    {selectedOffer.status === 'waiting_payment' && (
                      <button
                        className="linear mt-2 rounded-xl border-2 border-blue-300 px-5 py-2 text-sm font-medium text-blue-400 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5"
                        onClick={() => {
                          window.location.href = `${CONFIG.apiUrl}/stripe/checkout/${selectedOffer._id}`;
                        }}
                      >
                        Payer{' '}
                        {selectedOffer.buyerPrice.toFixed(2) *
                          selectedOffer.weeklyQuantity}
                        € HT
                      </button>
                    )}
                    {selectedOffer.status === 'running' && (
                      <>
                        <p className="mb-3 ml-2 text-gray-700">
                          💡 La totalité de vos leads arriveront d'ici à ~ 1
                          mois.
                        </p>
                        <InfoBox
                          icon={<MdOutlineRocketLaunch size={30} />}
                          title={'Mission en cours'}
                          description={`Vous avez déjà reçu ${
                            selectedOffer.leadsGenerated
                          } leads pour cette mission. Cela représente ${
                            selectedOffer.leadsGenerated *
                            selectedOffer.buyerPrice.toFixed(2)
                          }€.`}
                        />
                      </>
                    )}
                    {selectedOffer.status === 'done' && (
                      <InfoBox
                        icon={<MdDoneOutline size={30} />}
                        title={'Mission terminée'}
                        description={`Vous avez généré ${
                          selectedOffer.leadsGenerated
                        } leads pour un total de ${
                          selectedOffer.leadsGenerated *
                          selectedOffer.buyerPrice.toFixed(2)
                        } . Profitez de cette traction et cherchez un autre acheteur avec le même besoin`}
                      />
                    )}
                    {['on_market', 'waiting_payment'].includes(
                      selectedOffer.status
                    ) && (
                      <div className="flex justify-end">
                        <button
                          className="linear mx-auto mt-2 w-[200px] border-red-500 px-5 py-2 text-xs font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5"
                          onClick={() => {
                            deleteOffer(selectedOffer._id);
                            window.location.reload();
                          }}
                        >
                          Supprimer l'offre
                        </button>
                      </div>
                    )}
                  </>
                )}
                {pageState === 'informations' && (
                  <div className="w-full">
                    <div className="mb-2 mt-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Quantité:</p>
                      <p className="mb-1">
                        {selectedOffer.weeklyQuantity} leads
                      </p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Champs de données:</p>
                      <p className="mb-1">{selectedOffer.fields.join(', ')}</p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Canaux d'acquisition:</p>
                      <p className="mb-1">
                        {selectedOffer.channels.join(', ')}
                      </p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Besoin:</p>
                      <p className="mb-1">{selectedOffer.type}</p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Arguments:</p>
                      <p className="mb-1">{selectedOffer.whiteLabelInfos}</p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-1 font-bold">Sites concurents:</p>
                      <p className="mb-1">{selectedOffer.competitors}</p>
                    </div>
                    <div className="mb-2 flex items-center gap-5">
                      <p className="mb-2 font-bold"> Regions: </p>
                      <p className="mb-2">{selectedOffer.regions.join(', ')}</p>
                    </div>
                  </div>
                )}
                {pageState === 'steps' && (
                  <div className="mt-5 flex gap-2">
                    {selectedOffer.status === 'waiting_payment' && (
                      <button
                        className="linear rounded-xl border-2 border-blue-300 px-5 py-3 text-base font-medium text-blue-400 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5"
                        onClick={() => {
                          window.location.href = `${CONFIG.apiUrl}/stripe/checkout/${selectedOffer._id}`;
                        }}
                      >
                        Payer{' '}
                        {selectedOffer.buyerPrice.toFixed(2) *
                          selectedOffer.weeklyQuantity}
                        € HT
                      </button>
                    )}
                  </div>
                )}
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
export default ModalBuyer;
