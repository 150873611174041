import Card from "components/card";
import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { OfferStatus } from "variables/status";

const OfferCard = (props: {
  bidders: string[];
  industry: string;
  subIndustry: string;
  otherIndustry?: string;
  image: string;
  weeklyQuantity: number;
  extra?: string;
  status: string;
  targetPrice: string;
  onClick?: any;
  leadsGenerated?: number;
  buyerPrice?: any;
  user?: any;
}) => {
  const { industry, subIndustry, buyerPrice, otherIndustry, weeklyQuantity, status, image, extra, onClick, user, targetPrice, leadsGenerated } = props;
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    setOfferPercentage((leadsGenerated / weeklyQuantity) * 100);
  }, [leadsGenerated, weeklyQuantity]);

  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white border-2 shadow-xl cursor-pointer ${extra}`}
      onClick={onClick}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 max-h-[130px] w-full rounded-xl 3xl:h-full 3xl:w-full"
            alt=""
          />
        </div>

        <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
              {otherIndustry || subIndustry}
            </p>
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {' '}
              {`${industry}. `}
            </p>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 justify-between">
          <p className="mt-1 text-xs font-bold text-leadrs">
            {buyerPrice}€/leads
          </p>
          <p className="mt-1 text-sm font-bold text-leadrs">
            {leadsGenerated}/{weeklyQuantity} leads
          </p>
        </div>

        <div className="relative pt-1">
          <div className="flex flex-col">
            <div className="relative pt-1">
              <div className="mb-2 flex items-center justify-between">
                <div className="flex flex-1 items-center">
                  <div className="w-full rounded-full bg-gray-200">
                    <div
                      className={` ${offerPercentage ? `w-[5%]` : 'w-0'} h-3 rounded-full bg-leadrs`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
          <div className="text-leards flex">
            <div className="mt-[2px]">
              <FaLongArrowAltRight color="#7464fc" />
            </div>
            <p
              className={`mb-1 ml-2 text-[12px] font-bold ${
                status === 'not-paid' && 'animate-pulse'
              } ${
                status === 'not-paid' ? 'text-red-400' : 'text-leadrs'
              } dark:text-white`}
            >
              Disponible dès maintenant
              {user && !user.isValidated && 'En attente de validation'}
            </p>
          </div>
        </div>
      </div>
      <button
        className="text-small mt-2 flex w-full justify-center rounded-2xl bg-leadrs px-5 py-1 font-medium text-white hover:bg-leadrsLight"
        onClick={onClick}
      >
        Acheter pour {buyerPrice}€/leads
      </button>
    </Card>
  );
};

export default OfferCard;
