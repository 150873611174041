import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import { useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function SignInDefault() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email, password });
    axios
      .post(`${CONFIG.apiUrl}/auth/login`, body, config)
      .then(res => {
        console.log(res.data);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user-type', res.data.type);
		if (res.data.type === 'seller')
			window.location.href = '/admin/dashboard';
		else
			window.location.href = '/admin/my-request';
      })
      .catch(err => {
        console.log(err);
		toast.error(err.response.data.error);
      });
  };

  return (
    <Default
      maincard={
        <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0">
          {/* Sign in section */}
          <div className="w-full max-w-full flex-col items-center px-4 lg:px-0 lg:pl-0 xl:max-w-[420px]">
            {/* <img
              alt="logo leadrs"
              className="mb-5 h-10 lg:hidden"
              src="/assets/images/logo.png"
            ></img> */}
            <ToastContainer />
            <h3 className="mb-5 text-xl font-medium text-navy-700 dark:text-white lg:text-2xl">
              Se connecter
            </h3>
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Adresse mail"
              placeholder="hello@entreprise.fr"
              id="email"
              type="text"
              onChange={(e: any) => setEmail(e.target.value)}
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Mot de passe"
              placeholder="*********"
              id="password"
              type="password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">
              <div className="mt-2 flex items-center">
                <Checkbox extra="checked:bg-blue-400" />
                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Rester connecté
                </p>
              </div>
            </div>
            <button
              id="connect-btn"
              className="linear hover:bg-[#7166fe] w-full rounded-xl bg-[#7166fe] py-2 text-base font-medium text-white transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => login()}
            >
              Je me connecte
            </button>
          </div>
        </div>
      }
    />
  );
}

export default SignInDefault;
