import { useNavigate } from 'react-router-dom';

const GoBack = (props: { text?: string }) => {
  const { text = 'Retour' } = props;
  const nav = useNavigate();
  return (
    <div
      className="flex cursor-pointer items-center justify-start"
      onClick={() => nav(-1)}
    >
      <svg
        className="mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <p className="text-xs">{text}</p>
    </div>
  );
};
export default GoBack;
