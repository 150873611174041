import axios from 'axios';
import Card from 'components/card';
import { useEffect, useState } from 'react';
import { CONFIG } from 'variables/config';

const Invite = () => {
  const [isIntegrationSuccess, setIsIntegrationSuccess] = useState(null);
  const [user, setUser] = useState(null);

  const checkIntegration = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        console.log(res.data);
        if (res.data && res.data.isAlreadyConnected) {
          console.log('Integration success');
          setIsIntegrationSuccess(true);
        } else {
          console.log('Integration failed');
          setIsIntegrationSuccess(false);
        }
      })
      .catch(err => {
        console.log('Integration failed', err);
        setIsIntegrationSuccess(false);
      });
  };

  const initUser = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {
        console.log('No user info found', err);
      });
  };

  useEffect(() => {
    initUser();
  }, []);

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[30px] pr-[28px] pl-[33px]'}>
      <div className="mt-3">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Comment connecter votre landing à Leadrs
        </p>
        {/* links */}
        <p className="text-md mt-5 font-bold text-navy-700 dark:text-white">
          Via webflow
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
			Etape 1: Ouvrir la landing page que vous souhaitez connecter a leadrs.
        </p>
		<p className="mt-3 text-base leading-6 text-gray-600">
			Etape 2: Cliquer sur le CTA de votre formulaire.
        </p>
		<p className="mt-3 text-base leading-6 text-gray-600">
			Etape 3: Cliquer sur la roue cranté en haut a droite de votre page et aller dans <b>form settings</b>, puis dans la sous catégorie <b>SOURCE</b> et choisir <b>DEFAULT FORM</b>.
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
			Aller dans “ACTION” et copier l’URL ci dessous:
        </p>
        <div
          onClick={function () {
            navigator.clipboard.writeText(
              `${CONFIG.apiUrl}/lead/${user?._id}`
            );
          }}
          className="mt-4 flex h-fit w-full flex-wrap items-start gap-2 md:!flex-nowrap md:items-center lg:justify-between"
        >
          <div className="flex w-full items-center justify-between rounded-full bg-lightPrimary py-3 hover:cursor-pointer dark:!bg-navy-700 3xl:w-full">
            <p className="w-full px-3 text-sm font-medium text-navy-700 dark:text-white">
              {CONFIG.apiUrl}/lead/{user?._id}
            </p>
            <p className="w-full to-brand-500 pr-3 text-xs font-bold text-leadrs text-end dark:text-white">
              Copy Link
            </p>
          </div>
        </div>
		<p className="mt-3 text-base leading-6 text-gray-600">
			Aller dans “METHOD” et selectionner “POST”
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
		   Publier votre landing page et générer manuellement un lead avec l'adresse email
          'integrate@leadrs.co'.
        </p>
        <p className="text-md mt-5 font-bold text-navy-700 dark:text-white">
          Via typeform
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
          Rendez-vous sur la page <b>{' Connect > Webhooks > Add a webhook'}</b>
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
          Insérez le lien suivant:
        </p>
        <div
          onClick={function () {
            navigator.clipboard.writeText(
              `${CONFIG.apiUrl}/lead/${user?._id}`
            );
          }}
          className="mt-4 flex h-fit w-full flex-wrap items-start gap-2 md:!flex-nowrap md:items-center lg:justify-between"
        >
          <div className="flex w-full items-center justify-between rounded-full bg-lightPrimary py-3 hover:cursor-pointer dark:!bg-navy-700 3xl:w-full">
            <p className="w-full px-3 text-sm font-medium text-navy-700 dark:text-white">
              {CONFIG.apiUrl}/lead/{user?._id}
            </p>
            <p className="w-full to-brand-500 pr-3 text-xs font-bold text-leadrs text-end dark:text-white">
              Copy Link
            </p>
          </div>
        </div>
        <p className="mt-3 text-base leading-6 text-gray-600">
          Générez manuellement un lead avec l'adresse email
          'integrate@leadrs.co'.
        </p>
      </div>
      {/* <p className="mt-3 text-base leading-6 text-gray-600">
        Rendez-vous sur la page <b>{' Connect > Webhooks > Add a webhook'}</b>
      </p>
      <p className="mt-3 text-base leading-6 text-gray-600">
        Insérez le lien suivant:
      </p>
      <div
        onClick={function () {
          navigator.clipboard.writeText(
            'CONFIG.apiUrl/lead/64905087fbbd2b8f6fb27213'
          );
        }}
        className="mt-4 flex h-fit w-full flex-wrap items-start gap-2 md:!flex-nowrap md:items-center lg:justify-between"
      >
        <div className="flex w-full items-center justify-between rounded-full bg-lightPrimary py-3 hover:cursor-pointer dark:!bg-navy-700 3xl:w-full">
          <p className="w-full px-3 text-sm font-medium text-navy-700 dark:text-white">
            CONFIG.apiUrl/lead/64905087fbbd2b8f6fb27213
          </p>
          <p className="w-full to-brand-500 pr-3 text-xs font-bold text-leadrs text-end dark:text-white">
            Copy Link
          </p>
        </div>
      </div> */}
      {isIntegrationSuccess === false && (
        <p className="mt-3 text-base leading-6 text-red-400">
          <b>Erreur:</b> nous n'avons pas encore reçu le lead de test
          'integrate@leadrs.co'.
        </p>
      )}
      {isIntegrationSuccess ? (
        <button
          className="mt-5 h-12 w-full rounded-md bg-green-500 text-white hover:bg-green-600"
          onClick={() => (window.location.href = '/')}
        >
          Rejoindre mon dashboard
        </button>
      ) : (
        <button
          className="mt-5 h-12 w-full rounded-md bg-brand-500 text-white hover:bg-brand-600"
          onClick={checkIntegration}
        >
          Vérifier l'intégration
        </button>
      )}
    </Card>
  );
};

export default Invite;
