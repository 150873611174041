import Card from "components/card";
import InputField from "components/fields/InputField";

const Password = () => {
  return (
    <Card extra={"w-full mt-3 px-6 py-6 border-2"}>
      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Modifier son mot de passe
        </h4>
      </div>
      {/* inputs */}
      <div className="mt-9 grid grid-cols-1 gap-3">
        <InputField
          label="Ancien mot de passe"
          placeholder="*********"
          id="oldPassword"
          type="text"
        />
        <InputField
          label="Nouveau mot de passe"
          placeholder="*********"
          id="password"
          type="text"
        />
        <InputField
          label="Nouveau mot de passe"
          placeholder="*********"
          id="passwordConfirm"
          type="text"
        />

        <div className="mt-2 flex w-full justify-end">
          <button className="linear rounded-xl bg-brand-200 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-200 active:bg-brand-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Changer mon mot de passe
          </button>
        </div>
      </div>
      {/* full width inputs */}
    </Card>
  );
};

export default Password;
