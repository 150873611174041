import axios from 'axios';
import { useEffect, useState } from 'react';
import { CONFIG } from 'variables/config';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const StripeBilling = () => {
  const [user, setUser] = useState(null);

  const initUser = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        const user = res.data;
        setUser(user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    initUser();
  }, []);

  return (
    <div className="mx-auto mb-10 items-center text-center">
      {user && (
        <>
          <div className="mt-10">
            <stripe-pricing-table
              pricing-table-id="prctbl_1NQVmLDuoaNTg0ytqo0Ywp2N"
              publishable-key="pk_live_51L95Q7DuoaNTg0yt0Uu7KkrohDon9Voy7ZbI6skPJhPwOmB7l3titbGYgrhw535FJjNPyXh8g3hWOna64EcRY4Ff00UpjBHDFu"
              customer-email={user.email}
            ></stripe-pricing-table>
          </div>
          <p className="mt-5 text-lg">
            L'offre "Scale" est la plus adapté et la plus populaire.
          </p>
          <div className="mx-auto mt-[100px] flex max-w-screen-xl flex-col items-center pb-24">
            <h2 className="mb-2 text-center text-[35px] font-bold text-navy-700 dark:text-white">
              Questions fréquentes
            </h2>
            <div className="mt-16 grid w-full grid-cols-1 gap-12 px-3 lg:grid-cols-2">
              {/* left side */}
              <div className="">
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Comment garantir la qualité de mes leads ? Quel est le taux
                    de conversion ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Il y a énormément de variables qui rentrent en compte. Cela
                    dépend des marchés, de la saisonnalité, de la qualité de vos
                    commerciaux, etc …. L'important pour nous est de suivre
                    chaque KPI pour nous améliorer au quotidien et vous fournir
                    des leads qui atteignent la meilleure qualité possible.
                    Cependant, ce n'est pas qu'une question subjective. Toute
                    entreprise à des objectifs précis et nous mettons tout en
                    oeuvre chez Leadrs pour réaliser les objectifs de nos
                    clients. N'hésitez pas à nous communiquer vos ambitions
                    (dans la messagerie en bas de votre écran). Nous sommes
                    prêts à relever les challenges de votre entreprise !
                  </p>
                </div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Quelles sont les conditions de paiement ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Sur Leadrs vous avez la possibilité de choisir le prix
                    auquel vous souhaitez payer votre lead. Le prix est un
                    élément determinant dans votre recherche de lead. Cependant,
                    ce n'est pas le seul critère, en fonction de votre demande,
                    nous acceptons ou non, la mission que vous nous confiez.
                  </p>
                </div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Sous combien de temps je peux espérer avoir la totalité de
                    mes leads ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    {`En fonction de la quantité et de votre secteur les délais peuvent varier d'une semaine à un mois après le début de la mission. Cependant, il est important d'avoir en tête que chez Leadrs nous avons à coeur de vous fournir la meilleure qualité possible. (Qualité > Quantité > Vitesse).`}
                  </p>
                </div>
              </div>

              {/* rightsidde */}
              <div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Comment sont générés les leads ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Chez Leadrs, nos leads sont générés sur toutes les
                    plateformes de media buying. Google, Facebook, Taboola,
                    faites votre choix sur la plateforme qui vous convient le
                    mieux.
                  </p>
                </div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Les leads sont ils RGPD ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Oui, l'ensemble de nos leads sont en conformité RGPD. Vous
                    êtes l'unique responsable de l'utilisation des leads. Nous
                    nous réservons le droit d'interdire un acheteur de notre
                    plateforme en cas de démarchage telephonique abusifs ou
                    autres actions contraire aux valeurs de Leadrs.
                  </p>
                </div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Les leads sont ils livrés en temps réel ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Oui, l'ensemble de nos leads sont livrés en temps réel grâce
                    à un renvoi automatique du lead dès l'inscription. Vous
                    sommes conscients chez Leadrs que vous avez 75% de chance de
                    convertir un prospect si vous pouvez le joindre dans la
                    demi-journée de son inscription.
                  </p>
                </div>
                <div className="mb-[58px]">
                  <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                    Sous quelle forme sont livrés les leads ?
                  </h3>
                  <p className="mt-3 text-base font-medium text-gray-600">
                    Fini les tableaux excel ! L'ensemble de nos leads sont
                    livrés par mail ou sur votre CRM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StripeBilling;
