import {
  MdCall,
  MdContactMail,
  MdLeaderboard,
  MdOutlineShoppingCart,
  MdPayment,
} from 'react-icons/md';

function Default(props: { maincard: JSX.Element }) {
  const { maincard } = props;
  return (
    <div className="no-scrollbar relative grid h-screen w-full grid-cols-12">
      <div className="col-span-6 hidden h-full rounded-sm bg-gradient-to-r from-[#7166fe] via-[#7166fe] to-[#7166fe] md:block">
        {/* <img
          alt="logo leards"
          className="ml-7 mt-5"
          src="../../assets/logo.png"
        ></img> */}
        <p className="ml-5 mt-[35%] px-10 text-[40px] font-bold text-white">
          Dealer de leads.
        </p>
        <p className="ml-5 mt-5 px-10 text-xl font-semibold text-white">
          Recevez des leads exclusifs, ultra qualifiés et RGPD compliant prêts à
          convertir, directement dans votre CRM.
        </p>
        {/* Top */}
        <div className="absolute left-[6%] top-[10%] rotate-12 transform text-blue-300 opacity-25">
          <MdPayment size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[27%] top-[1%] rotate-12 transform text-blue-300 opacity-25">
          <MdContactMail size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[17%] top-[16%] -rotate-6 transform text-blue-300 opacity-25">
          <MdOutlineShoppingCart size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[38%] top-[20%] -rotate-6 transform text-blue-300 opacity-25">
          <MdLeaderboard size="60" className="ml-[15%] mt-10" />
        </div>
        {/* Bottom */}
        <div className="absolute left-[4%] top-[70%] rotate-12 transform text-blue-300 opacity-25">
          <MdOutlineShoppingCart size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[25%] top-[75%] rotate-12 transform text-blue-300 opacity-25">
          <MdLeaderboard size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[30%] top-[48%] rotate-12 transform text-blue-300 opacity-25">
          <MdCall size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[14%] top-[55%] -rotate-6 transform text-blue-300 opacity-25">
          <MdContactMail size="60" className="ml-[15%] mt-10" />
        </div>
        <div className="absolute left-[38%] top-[60%] -rotate-6 transform text-blue-300 opacity-25">
          <MdPayment size="60" className="ml-[15%] mt-10" />
        </div>
        <p className="absolute bottom-1 ml-2 text-sm text-white">
          ©{new Date().getFullYear()} Leadrs.co . All Rights Reserved.{' '}
        </p>
      </div>
      <div className="col-span-12 md:col-span-6 md:ml-10">{maincard}</div>
    </div>
  );
}

export default Default;
