import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import { useState } from 'react';
import axios from 'axios';
import { CONFIG } from 'variables/config';

function SignUpDefault() {
  const [error, setError] = useState('');
  const [newUser, setNewUser] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    siret: '',
    company: '',
    type: 'buyer',
    role: 'ceo',
    accept_tos: false,
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;

	if (id === 'accept_tos') {
		setNewUser((prevState) => ({
			...prevState,
			[id]: !prevState.accept_tos,
		}));
		return;
	}
    setNewUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setError('');
    console.log(newUser);
  };

  const handleSubmit = (e: any) => {
    console.log(newUser);
    axios
      .post(`${CONFIG.apiUrl}/auth/register`, newUser)
      .then((res: any) => {
        console.log(res);
         localStorage.setItem('user-type', newUser.type);
        localStorage.setItem('token', res.data.token);
        if (res.data && res.data.token.length > 10)
          window.location.href = '/admin/new-offer';
      })
      .catch((err: any) => {
        console.log(err.response.data.error);
        setError(err.response.data.error);
      });
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign up section */}
          <div className="mt-[10vh] mx-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
              Rejoindre Leadrs !
            </h3>
            <p className="ml-1 mt-[10px] text-base text-gray-600">
              Achat de leads qualifiés à la demande.
            </p>
            <p className="ml-1 mt-[10px] text-base text-gray-600">
              <span className="text-red-500"> {error} </span>
            </p>
            {/* <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:!bg-navy-800">
              <div className="rounded-full text-xl">
                <FcGoogle />
              </div>
              <p className="text-sm font-medium text-navy-700 dark:text-white">
                Sign Up with Google
              </p>
            </div>
            <div className="mt-6 mb-4 flex items-center gap-3">
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
              <p className="text-base font-medium text-gray-600"> or </p>
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div> */}
            {/* user info */}
            <div className="mb-3 mt-3 flex w-full items-center justify-center gap-4">
              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Prénom*"
                  placeholder="Jeff"
                  id="name"
                  type="text"
                  onChange={handleChange}
                />
              </div>

              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Nom*"
                  placeholder="Bezos"
                  id="surname"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="contact@leadrs.co"
              id="email"
              type="email"
              onChange={handleChange}
            />
            {/* Siret */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Siret*"
              placeholder="123 568 941 00056"
              id="siret"
              type="text"
              onChange={handleChange}
            />
            {/* Company */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Entreprise*"
              placeholder="Samsung"
              id="company"
              type="text"
              onChange={handleChange}
            />
            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Mot de passe"
              placeholder="********"
              id="password"
              type="password"
              onChange={handleChange}
            />
            {/* Checkbox */}
            <div className="mt-4 flex items-center justify-between px-2">
              <div className="flex">
                <Checkbox id="accept_tos" onClick={handleChange} />
                <label
                  htmlFor="checkbox"
                  className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                >
                  Je suis le représentant légal de l'entreprise et accepte les{' '}
                  <a
                    href="/auth/sign-in/default"
                    className="ml-1 text-sm font-medium text-leadrs hover:text-brand-600 dark:text-white"
                  >
                    Conditions Générales d'Utilisation
                  </a>
                </label>
              </div>
            </div>

            {/* button */}

            <button
              className="linear mt-4 w-full rounded-xl bg-[#7166fe] py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={e => handleSubmit(e)}
            >
              M'inscrire
            </button>

            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Déjà inscrit ?
              </span>
              <a
                href="/auth/sign-in/"
                className="ml-1 text-sm font-medium text-leadrs hover:text-brand-600 dark:text-white"
              >
                Se connecter
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignUpDefault;
